.PlaygroundEditorTheme__ltr {
  text-align: left !important;
}
.PlaygroundEditorTheme__rtl {
  text-align: right;
}
.PlaygroundEditorTheme__paragraph {
  margin: 0;
  position: relative;
}
.PlaygroundEditorTheme__paragraph[dir="ltr"] {
  text-align: left !important;
}
.PlaygroundEditorTheme__quote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.PlaygroundEditorTheme__h3 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
}
.PlaygroundEditorTheme__h4 {
  font-size: 20px;
  color: rgb(101, 103, 107);
  font-weight: 600;
  margin: 0;
}
.PlaygroundEditorTheme__indent {
  --lexical-indent-base-value: 40px;
}
.PlaygroundEditorTheme__textBold {
  font-weight: bold;
}
.PlaygroundEditorTheme__textItalic {
  font-style: italic;
}
.PlaygroundEditorTheme__textUnderline {
  text-decoration: underline;
}
.PlaygroundEditorTheme__textStrikethrough {
  text-decoration: line-through;
}
.PlaygroundEditorTheme__textUnderlineStrikethrough {
  text-decoration: underline line-through;
}
.PlaygroundEditorTheme__textSubscript {
  font-size: 0.8em;
  vertical-align: sub !important;
}
.PlaygroundEditorTheme__textSuperscript {
  font-size: 0.8em;
  vertical-align: super;
}
.PlaygroundEditorTheme__textCode {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}
.PlaygroundEditorTheme__hashtag {
  background-color: rgba(88, 144, 255, 0.15);
  border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}
.PlaygroundEditorTheme__link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.PlaygroundEditorTheme__link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.PlaygroundEditorTheme__code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
}
.PlaygroundEditorTheme__code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.PlaygroundEditorTheme__table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: max-content;
  margin: 30px 0;
}
.PlaygroundEditorTheme__tableSelection *::selection {
  background-color: transparent;
}
.PlaygroundEditorTheme__tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.PlaygroundEditorTheme__tableCell {
  border: 1px solid #bbb;
  width: 75px;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  outline: none;
}
.PlaygroundEditorTheme__tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.PlaygroundEditorTheme__tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.PlaygroundEditorTheme__tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.PlaygroundEditorTheme__tableCellSelected {
  background-color: #c9dbf0;
}
.PlaygroundEditorTheme__tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.PlaygroundEditorTheme__tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.PlaygroundEditorTheme__tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: -25px;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.PlaygroundEditorTheme__tableAddColumns:after {
  background-image: url(../images/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.PlaygroundEditorTheme__tableAddColumns:hover {
  background-color: #c9dbf0;
}
.PlaygroundEditorTheme__tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.PlaygroundEditorTheme__tableAddRows:after {
  background-image: url(../images/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.PlaygroundEditorTheme__tableAddRows:hover {
  background-color: #c9dbf0;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PlaygroundEditorTheme__tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.PlaygroundEditorTheme__tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.PlaygroundEditorTheme__tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.PlaygroundEditorTheme__tableCellActionButton:hover {
  background-color: #ddd;
}
.PlaygroundEditorTheme__characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.PlaygroundEditorTheme__ol1 {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.PlaygroundEditorTheme__ol2 {
  padding: 0;
  margin: 0;
  list-style-type: upper-alpha;
  list-style-position: outside;
}
.PlaygroundEditorTheme__ol3 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: outside;
}
.PlaygroundEditorTheme__ol4 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: outside;
}
.PlaygroundEditorTheme__ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-roman;
  list-style-position: outside;
}
.PlaygroundEditorTheme__ul {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.PlaygroundEditorTheme__listItem {
  margin: 0 32px;
}
.PlaygroundEditorTheme__listItemChecked,
.PlaygroundEditorTheme__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.PlaygroundEditorTheme__listItemChecked {
  text-decoration: line-through;
}
.PlaygroundEditorTheme__listItemUnchecked:before,
.PlaygroundEditorTheme__listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.PlaygroundEditorTheme__listItemUnchecked[dir='rtl']:before,
.PlaygroundEditorTheme__listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.PlaygroundEditorTheme__listItemUnchecked:focus:before,
.PlaygroundEditorTheme__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.PlaygroundEditorTheme__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.PlaygroundEditorTheme__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.PlaygroundEditorTheme__listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.PlaygroundEditorTheme__nestedListItem {
  list-style-type: none;
}
.PlaygroundEditorTheme__nestedListItem:before,
.PlaygroundEditorTheme__nestedListItem:after {
  display: none;
}
.PlaygroundEditorTheme__tokenComment {
  color: slategray;
}
.PlaygroundEditorTheme__tokenPunctuation {
  color: #999;
}
.PlaygroundEditorTheme__tokenProperty {
  color: #905;
}
.PlaygroundEditorTheme__tokenSelector {
  color: #690;
}
.PlaygroundEditorTheme__tokenOperator {
  color: #9a6e3a;
}
.PlaygroundEditorTheme__tokenAttr {
  color: #07a;
}
.PlaygroundEditorTheme__tokenVariable {
  color: #e90;
}
.PlaygroundEditorTheme__tokenFunction {
  color: #dd4a68;
}
.PlaygroundEditorTheme__mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.PlaygroundEditorTheme__markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.PlaygroundEditorTheme__mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.PlaygroundEditorTheme__markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.PlaygroundEditorTheme__embedBlock {
  user-select: none;
}
.PlaygroundEditorTheme__embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}
.PlaygroundEditorTheme__layoutContainer {
  display: grid;
  gap: 10px;
  margin: 10px 0;
}
.PlaygroundEditorTheme__layoutItem {
  border: 1px dashed #ddd;
  padding: 8px 16px;
}
.PlaygroundEditorTheme__autocomplete {
  color: #ccc;
}
.PlaygroundEditorTheme__hr {
  padding: 2px 2px;
  border: none;
  margin: 1em 0;
  cursor: pointer;
}
.PlaygroundEditorTheme__hr:after {
  content: '';
  display: block;
  height: 2px;
  background-color: #ccc;
  line-height: 2px;
}
.PlaygroundEditorTheme__hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-shell span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
  justify-content: center;
  margin: 0 auto;
  left: 22%;
}

.editor-shell .editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-shell .editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  -webkit-user-select: none;
  user-select: none;
}

.editor-shell .editor-image .image-caption-container {
  display: grid;
  padding: 0px;
  margin: 0 auto;
  background-color: #ffffffe6;
  max-width: 500px;
  color: #000;
  overflow: hidden;
}

/* .editor-shell .editor-image .image-resizer.image-resizer-n{
  top: -6px;
  left: 48%;
  cursor: n-resize;
} */

.editor-shell .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

/* .editor-shell .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
} */

.editor-shell .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

/* .editor-shell .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
} */

.editor-shell .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

/* .editor-shell .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
} */

.editor-shell .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

/* .editor-shell .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
} */

.editor-shell .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.editor-shell span.inline-editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.editor-shell .inline-editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .inline-editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
}

.editor-shell .inline-editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .inline-editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .inline-editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .inline-editor-image.position-full {
  margin: 1em 0 1em 0;
}

.editor-shell .inline-editor-image.position-left {
  float: left;
  width: 50%;
  margin: 1em 1em 0 0;
}

.editor-shell .inline-editor-image.position-right {
  float: right;
  width: 50%;
  margin: 1em 0 0 1em;
}
.editor-shell .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-shell .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-shell span.inline-editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.editor-shell .inline-editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .inline-editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
}

.editor-shell .inline-editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .inline-editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .inline-editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .inline-editor-image.position-full {
  margin: 1em 0 1em 0;
}

.editor-shell .inline-editor-image.position-left {
  float: left;
  width: 50%;
  margin: 1em 1em 0 0;
}

.editor-shell .inline-editor-image.position-right {
  float: right;
  width: 50%;
  margin: 1em 0 0 1em;
}

.editor-shell .inline-editor-image .image-edit-button {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px 8px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 60px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-shell .inline-editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-shell .inline-editor-image .image-caption-container {
  display: block;
  background-color: #f4f4f4;
  min-width: 100%;
  color: #000;
  overflow: hidden;
}
